<template>
  <div>
    <ul>
      <li v-for="(todo, key) in result.todo" :key="key">
        <icon class="icon" id="circle-empty-alert" />
        <span>{{ todo.msg }}</span>
        <saveBtn
          v-if="todo.site"
          class="saveBtn"
          :callback="function () { goTo(todo.site) }"
        />
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    result: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    goTo(name) {
      this.$router.push({ name: name });
    }
  }
};
</script>
<style lang="sass" scoped>
ul
  padding-left: 0
  li
    list-style: none
    display: flex
    gap: 5px
    align-items: center
</style>
